const coverVid = function (elem, width, height, options) {
  // call sizeVideo on load
  sizeVideo();

  // debounce for resize function
  function debounce(fn, delay) {
    let timer = null;

    return function () {
      const context = this;
      const args = arguments;

      window.clearTimeout(timer);

      timer = window.setTimeout(function () {
        fn.apply(context, args);
      }, delay);
    };
  }

  // call sizeVideo on resize
  window.addEventListener('resize', debounce(sizeVideo, 50));

  // Set necessary styles to position video "center center"
  elem.style.position = 'absolute';
  elem.style.top = '50%';
  elem.style.left = '50%';
  elem.style['-webkit-transform'] = 'translate(-50%, -50%)';
  elem.style['-ms-transform'] = 'translate(-50%, -50%)';
  elem.style.transform = 'translate(-50%, -50%)';

  // Set overflow hidden on parent element
  elem.parentNode.style.overflow = 'hidden';

  // Get image defined on poster attribute of video
  const posterImage = elem.getAttribute('poster');

  if (posterImage) {
    // Remove poster to disable
    elem.removeAttribute('poster');
    // Set poster image as a background cover image on parent element
    elem.parentNode.style.backgroundImage = 'url(' + posterImage + ')';
    elem.parentNode.style.backgroundSize = 'cover';
    elem.parentNode.style.backgroundPosition = 'center center';
  }

  // Define the attached selector
  function sizeVideo() {
    // Get parent element height and width
    const parentHeight = document.querySelector('.covervid-video').parentNode
      .offsetHeight;
    const parentWidth = document.querySelector('.covervid-video').parentNode
      .offsetWidth;

    // Get native video width and height
    const nativeWidth = width;
    const nativeHeight = height;

    // Get the scale factors
    const heightScaleFactor = parentHeight / nativeHeight;
    const widthScaleFactor = parentWidth / nativeWidth;

    // Based on highest scale factor set width and height
    if (widthScaleFactor > heightScaleFactor) {
      elem.style.height = 'auto';
      elem.style.width = parentWidth + 'px';
    } else {
      // If device is mobile and video width is smaller than parent width
      if (options.isMobile && options.srcWidth < parentWidth) {
        const scaleFactor =
          (parentWidth / options.srcWidth) * (options.srcHeight / parentHeight);
        // Scale up to avoid black stripes on the side
        elem.style.height = Math.ceil(parentHeight * scaleFactor) + 'px';
        elem.style.width = 'auto';
      } else {
        elem.style.height = parentHeight + 'px';
        elem.style.width = 'auto';
      }
    }
  }

  // Check for video support
  const supportsVideo = typeof elem.canPlayType !== 'undefined';
  // Remove video if not supported or mobile
  if (!supportsVideo) {
    elem && elem.parentNode && elem.parentNode.removeChild(elem);
  }
};

export default coverVid;
